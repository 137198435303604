import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  MenuItem,
  Menu,
  Paper,
  Grid,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { useHistory } from "react-router-dom";
import { useApi } from "../API";
import { numberWithCommas } from "../utils";
import NewModal from "./ApplicationNewModal";
import EditModal from "./ApplicationEditModal";
import DeleteModal from "./ApplicationDeleteModal";
import ResilienceIcon from "../icons/resilienceIcon";

const useStyles = makeStyles(() => ({
  textField: {
    minWidth: "70%",
  },
  tableText: {
    whiteSpace: "nowrap",
    minWidth: "120px",
    maxWidth: "120px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

function ApplicationsTable() {
  const api = useApi();
  const history = useHistory();
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [newModalOpen, setNewModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [visible, setVisible] = useState();

  const [refreshData, setRefreshData] = useState(false);
  const [modalApplication, setModalApplication] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const toggleRefresh = (event) => {
    event.stopPropagation();
    setRefreshData(!refreshData);
  };

  const handleClickNewApplication = (event) => {
    event.stopPropagation();
    setNewModalOpen(true);
  };

  useEffect(async () => {
    setLoading(true);
    try {
      const result = await api.getApplicationsDetails();
      setData(result);
    } catch (e) {
      setError(true);
      setData([]);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [refreshData]);

  const handleClickMenu = (event, application) => {
    event.stopPropagation();
    setModalApplication(application);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setModalApplication(null);
    setAnchorEl(null);
  };

  const handleHover = (rowId) => {
    setVisible(rowId);
  };

  const handleMouseLeave = () => {
    setVisible(null);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>There was an error fetching the data.</div>;
  }

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        style={{ marginBottom: ".5em" }}
      >
        <Grid item />
        <Grid itme>
          {" "}
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            color="secondary"
            size="small"
            disableElevation
            onClick={handleClickNewApplication}
          >
            New Application
          </Button>
        </Grid>
      </Grid>

      <TableContainer>
        <Table size="small" component={Paper} variant="outlined">
          <colgroup>
            <col />
            <col style={{ width: "20%" }} />
            <col />
            <col />
            <col />
            <col />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Name</TableCell>
              <TableCell>Business Process</TableCell>
              <TableCell>Assets</TableCell>
              <TableCell>Vulnerabilites</TableCell>
              <TableCell>Alerts</TableCell>
              {/* <TableCell>Annual Revenue</TableCell> */}
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((application, i) => (
              <>
                <TableRow
                  onClick={() => history.push(
                    `/app/inventory/business-applications/${application.id}`
                  )}
                  onMouseLeave={handleMouseLeave}
                  onMouseOver={() => handleHover(application.id)}
                  hover
                  key={i}
                >
                  <TableCell>
                    <Tooltip title="View Connections">
                      <IconButton
                        color="secondary"
                        target="_blank"
                        href={`/app/resilience/connections/application/${application.id}`}
                      >
                        <ResilienceIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="View Assets">
                      <Button
                        variant="outlined"
                        size="small"
                        disableElevation
                        color="secondary"
                        target="_blank"
                        href={`/app/inventory/assets?application_id=${application.id}`}
                      >
                        Assets
                      </Button>
                    </Tooltip>
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      history.push(
                        `/app/inventory/assets?application_id=${application.id}`
                      );
                    }}
                    className={classes.tableText}
                    style={{ cursor: "pointer" }}
                  >
                    {application.display_name}
                  </TableCell>
                  <TableCell
                    className={classes.tableText}
                  >
                    {application.business_process_name}
                  </TableCell>
                  <TableCell
                    onClick={() => {
                      history.push(
                        `/app/inventory/assets?application_id=${application.id}`
                      );
                    }}
                    className={classes.tableText}
                    style={{ cursor: "pointer" }}
                  >
                    {numberWithCommas(application.total_assets)}
                  </TableCell>
                  <TableCell className={classes.tableText}>
                    {numberWithCommas(application.total_cves)}
                  </TableCell>
                  <TableCell className={classes.tableText}>
                    {numberWithCommas(application.total_alerts)}
                  </TableCell>
                  {/* <TableCell className={classes.tableText}>
                    {abbrCurrencyFormatter(application.annual_revenue)}
                  </TableCell> */}
                  {/* <TableCell className={classes.tableText}>
                    {application.owner}
                  </TableCell> */}
                  <TableCell style={{ textAlign: "right" }}>
                    <IconButton
                      id="edit-btn"
                      color="secondary"
                      style={{
                        visibility:
                          application.id === visible ? "visible" : "hidden",
                      }}
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClickMenu(e, application);
                        setAnchorEl(null);
                        setEditModalOpen(true);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      id="delete-btn"
                      color="secondary"
                      style={{
                        visibility:
                          application.id === visible ? "visible" : "hidden",
                      }}
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClickMenu(e, application);
                        setAnchorEl(null);
                        setDeleteModalOpen(true);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                    {/* <Button onClick={() => {
                                            setModalApplication(application);
                                            setModalOpen(true)
                                        }} variant="outlined" size="small" >
                                            Manage Assets
                                        </Button> */}
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            setEditModalOpen(true);
          }}
        >
          Edit Business Application
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAnchorEl(null);
            setDeleteModalOpen(true);
          }}
        >
          Delete Business Application
        </MenuItem>
      </Menu>
      <EditModal
        open={editModalOpen}
        application={modalApplication}
        toggleRefresh={toggleRefresh}
        onClose={() => {
          setModalApplication(null);
          setEditModalOpen(false);
          setAnchorEl(null);
        }}
      />
      <NewModal
        open={newModalOpen}
        toggleRefresh={toggleRefresh}
        onClose={() => {
          setModalApplication(null);
          setNewModalOpen(false);
        }}
      />
      <DeleteModal
        open={deleteModalOpen}
        application={modalApplication}
        toggleRefresh={toggleRefresh}
        onClose={() => {
          setModalApplication(null);
          setDeleteModalOpen(false);
          setAnchorEl(null);
        }}
      />
    </>
  );
}

export default ApplicationsTable;
