import React, { useEffect, useRef, useState } from "react";
import { Card, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ResponsiveBar } from "@nivo/bar";
import { useApi } from "../../API";
import { useHistory } from "react-router-dom";
import { palette } from "../../utils/theme";
import UseResizeObserver from "../../components/UseResizeObserver";
import ChartWrapper from "../../components/ChartWrapper";
import ChartWrapperHeader from "../../components/ChartWrapperHeader";
const useStyles = makeStyles(() => ({
  chartWrapper: {
    height: "400px",
  },
}));

// Hotfix for demo - capitalize the name, really we should be using the display name of the solution.
const renderTooltip = (data) => {
  return (
    <div
      style={{
        backgroundColor: "white",
        color: "inherit",
        fontSize: "inherit",
        borderRadius: "2px",
        boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 2px",
        padding: "5px 9px",
      }}
    >
      {(data.id === "critical_assets"
        ? "High Impact Assets: "
        : "All Assets: ") + _.capitalize(data.indexValue?.replaceAll("_", " "))}
      : <strong>{data.value?.toFixed(0)}</strong>
    </div>
  );
};


function CyberAssetsByDatasource() {
  const [data, setData] = useState([]);
  const [criticalOnly, setCriticalOnly] = useState(false);
  const [dateFilter, setDateFilter] = useState(false);
  const [logoData, setLogoData] = useState(null);
  const [total, setTotal] = useState(null);
  const [highImpact, setHighImpact] = useState(null);

  // const parentRef = useRef();
  // const dimensions = UseResizeObserver(parentRef);
  const history = useHistory();

  const classes = useStyles();
  const api = useApi();

  const CustomTick = (tick) => {
    const logoUrl = logoData.find((el) => el.name === tick.value)?.logo_url;
    const displayName = logoData.find((el) => el.name === tick.value)?.name;
    if (!logoUrl || logoUrl === "") {
      return (
        <g transform={`translate(${tick.x - 90},${tick.y - 10})`}>
          <text>{displayName}</text>
        </g>
      );
    }
    return (
      <g transform={`translate(${tick.x - 90},${tick.y - 10})`}>
        <image
          width="80px"
          // href={logoData?.find((el) => el.name === tick.value)?.logo_url}
          alt={`${displayName} logo`}
          href={tick.value === "keycaliber" ? logo : logoUrl}
        />
      </g>
    );
  };
  const formatResponseForChart = (response) => {
    const formatted = [];

    for (const solution of response) {
      formatted.push({
        all_assets: solution.total,
        all_assets_percentage: solution.percent,
        critical_assets: solution.high_impact_total,
        critical_assets_percentage: solution.high_impact_percent,
        key: solution.name,
        id: solution.id,
      });
    }

    return formatted.sort((a, b) => (b.id > a.id ? -1 : 1));
  };
  useEffect(() => {
    api.settingsNavbar().then((res) => {
      setHighImpact(res.num_critical);
      setTotal(res.num_total);
    });
  }, []);

  useEffect(() => {
    api
      .coverageSummary(dateFilter)
      .then((response) => {
        setLogoData(response.logos);
        setData(formatResponseForChart(response.data));

      })
      .catch((e) => {
        console.log(e);
      });
  }, [dateFilter]);

  return (
    <ChartWrapper excludeHeader>
      <ChartWrapperHeader chartName="Cyber Assets by Data Source">
      </ChartWrapperHeader>
      <Grid
        container
        direction="row"
        justifyContent="space-around"
        textAlign="center"
        style={{ marginTop: "24px", marginBottom: "16px" }}
      >
        <Grid container item xs={4} justifyContent="center">
          <strong>All Assets</strong>
        </Grid>
        <Grid container item xs={4} justifyContent="center">
          {" "}
        </Grid>
        <Grid container item xs={4} justifyContent="center">
          <strong>High Impact Assets</strong>
        </Grid>
      </Grid>
      <Card>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          textAlign="center"
          style={{ marginTop: "24px", marginBottom: "24px" }}
        >

          {data?.length > 0 && (
            <Grid
              item
              // style={{ paddingTop: "1em" }}
              xs={6}
              className={classes.chartWrapper}
              data-test="assets-location-wrapper1"
            >
              <ResponsiveBar
                onClick={(data) => {
                  let url = `/app/inventory/assets?missing_coverage=${data.data.id
                    }${data.id === "critical_assets" ? "&is_critical=true" : ""}`;
                  if (dateFilter) {
                    url += "&stale=false";
                  }
                  history.push(url);
                }}
                enableLabel
                labelTextColor="white"
                label={(d) => `${parseFloat(d.data.all_assets).toFixed()}`}
                data={data}
                keys={["all_assets"]}
                minValue={0}
                maxValue={total}
                indexBy="key"
                height={400}
                margin={{
                  top: 30,
                  right: 80,
                  bottom: 60,
                  left: 145,
                }}
                onMouseEnter={(_datum, event) => {
                  event.currentTarget.style.cursor = "pointer";
                }}
                axisLeft={{
                  tickSize: 0,
                  renderTick: CustomTick,
                }}
                gridXValues={5}
                axisBottom={{
                  tickSize: 0,
                  tickValues: 4,
                  legendPosition: "middle",
                  legendOffset: 50,
                  legend: "# Assets Covered",
                  format: (d) => `${d}`,
                }}
                groupMode="grouped"
                colors={[palette.keyBlue]}
                padding={0.3}
                layout="horizontal"
                borderColor="inherit:darker(1.6)"
                enableGridX
                enableGridY

                isInteractive
                theme={{
                  axis: {
                    domain: {
                      line: {
                        stroke: "#a9acbe",
                        strokeWidth: 1,
                      },
                    },
                  },
                  grid: {
                    line: {
                      stroke: "#e7e8ec",
                      strokeWidth: 1,
                    },
                  },
                }}

                tooltip={renderTooltip}
              />
            </Grid>
          )}
          {data?.length > 0 && (
            <Grid
              item
              // style={{ paddingTop: "1em" }}
              xs={6}
              className={classes.chartWrapper}
              data-test="assets-location-wrapper1"
            >
              <ResponsiveBar
                onClick={(data) => {
                  let url = `/app/inventory/assets?missing_coverage=${data.data.id
                    }${data.id === "critical_assets" ? "&is_critical=true" : ""}`;
                  if (dateFilter) {
                    url += "&stale=false";
                  }
                  history.push(url);
                }}
                onMouseEnter={(_datum, event) => {
                  event.currentTarget.style.cursor = "pointer";
                }}
                enableLabel
                labelTextColor="white"
                label={(d) => `${parseFloat(d.data.critical_assets).toFixed()}`}
                data={data}
                keys={["critical_assets"]}
                indexBy="key"
                height={400}
                minValue={0}
                maxValue={highImpact}
                margin={{
                  top: 30,
                  right: 80,
                  bottom: 60,
                  left: 145,
                }}
                axisLeft={{
                  tickSize: 0,
                  renderTick: CustomTick,
                }}
                gridXValues={5}
                axisBottom={{
                  tickSize: 0,
                  tickValues: 4,
                  legendPosition: "middle",
                  legendOffset: 50,
                  legend: "# High Impact Assets Covered",
                  format: (d) => `${d}`,
                }}
                groupMode="grouped"
                colors={[palette.purple1]}
                padding={0.3}
                layout="horizontal"
                borderColor="inherit:darker(1.6)"
                enableGridX
                enableGridY
                isInteractive
                theme={{
                  axis: {
                    domain: {
                      line: {
                        stroke: "#a9acbe",
                        strokeWidth: 1,
                      },
                    },
                  },
                  grid: {
                    line: {
                      stroke: "#e7e8ec",
                      strokeWidth: 1,
                    },
                  },
                }}

                tooltip={renderTooltip}
              />
            </Grid>
          )}
        </Grid>
      </Card>
    </ChartWrapper>
  );
}
export default CyberAssetsByDatasource;
