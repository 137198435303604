import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import {
  FormControlLabel,
  Checkbox,
  Badge,
  Grid,
  Divider,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Button,
} from "@material-ui/core";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import TuneIcon from "@material-ui/icons/Tune";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";
import CheckboxesFacet from "../components/CheckboxesFacet";
import { palette } from "../utils/theme";
import { useApi } from "../API";
import useQuery from "../hooks/useQuery";

const useStyles = makeStyles(() => ({
  paper: {
    height: "100%",
    display: "flex",
  },
  toggleButton: {
    padding: ".5rem",
    width: "3rem",
    height: "3rem",
    background: "white",
    border: `1px solid ${palette.background}`,
    "&:hover": {
      background: palette.lightGray,
    },
  },
  facetContainer: {
    padding: ".5em 1em",
  },
  container: {
    transition: "width 0.25s",
    borderRight: `1px ${palette.lightGray} solid`,
  },
  filterPanel: {
    direction: "rtl",
    position: "relative",
    width: "100%",
    // padding: "20px 20px",
    flex: "0 0 300px",
    height: "calc(100vh - 16em)",
    // borderRight: "1px solid",
    borderRightColor: palette.lightGray,
    overflowX: "hidden",
  },
  filterHeaderIcon: {
    position: "absolute",
    right: 5,
    top: 5,
    zIndex: 100,
  },
  fieldContainer: {
    margin: ".4rem 0 .8rem 0",
  },
  filterHeading: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  sliderContainer: {
    margin: "0 2px",
    paddingBottom: 0,
    marginBottom: 0,
  },
  textField: {
    margin: "5px 0",
    width: "300px",
  },
  clearAll: {
    fontSize: ".75rem",
    lineHeight: 2.66,
    paddingRight: "1rem",
    "&:hover": {
      textDecoration: "underline",
      cursor: "pointer",
    },
  },
  cvesWrapper: {
    display: "flex",
    lineHeight: "3rem",
  },
  cvesInput: {
    width: "100px",
    marginRight: ".5rem",
    marginLeft: ".5rem",
  },
  heading: {
    width: "100%",
    padding: "0",
  },
  selectionWidget: {
    float: "right",
  },
}));

const Filters = (props) => {
  const classes = useStyles();
  const [filterPanelOpen, setFilterPanelOpen] = useState(props.filterPanelOpen);
  const [clearCt, setClearCt] = useState(0);
  const api = useApi();
  const history = useHistory();
  const urlSearchQuery = useQuery();
  const [cisaChecked, setCisaChecked] = useState(false);
  const [internetFacingChecked, setInternetFacingChecked] = useState(false);
  // const [internetFacingChecked, setInternetFacingChecked] = useState(false);
  const [criticalOnlyChecked, setCriticalOnlyChecked] = useState(false);
  const [notCriticalOnlyChecked, setNotCriticalOnlyChecked] = useState(false);
  // set a state variable for the facets
  const [facetMap, setFacetMap] = useState(undefined);
  const [impactRangeValue, setImpactRangeValue] = useState([0, 100]);
  const [riskRangeValue, setRiskRangeValue] = useState([0, 100]);
  const [coverageOperator, setCoverageOperator] = useState("or");
  const [maxCvssRangeValue, setMaxCvssRangeValue] = useState([0, 10]);
  const EXCLUDED_FACETS = ["os_version"];

  const resolveCriticalParameter = (id, lowOnly, highOnly) => {
    if (highOnly && !lowOnly) {
      updateFacet(id, true, true);
    } else if (lowOnly && !highOnly) {
      updateFacet(id, false, true);
    } else {
      // Case where both or none are checked
      updateFacet(id, null, false);
    }
  };

  const toggleFilters = (isVisible) => {
    setFilterPanelOpen(isVisible);
  };

  const validateUrlSliderParams = (value, validRange) => {
    if (!value) {
      return false;
    }

    const splitValues = value.split(",");

    if (splitValues.length !== 2) {
      return false;
    }

    const nums = splitValues.map((el) => parseInt(el));

    if (nums.includes(NaN)) {
      return false;
    }

    // ignore it if the range is the same as the defaults
    if (nums[0] === validRange[0] && nums[1] === validRange[1]) {
      return false;
    }

    // ignore the values if on of them if out of the slider range
    if (nums[0] < validRange[0] || nums[0] > validRange[1]) {
      return false;
    }

    if (nums[1] < validRange[0] || nums[1] > validRange[1]) {
      return false;
    }

    return nums;
  };

  // set active states for each facet
  useEffect(() => {
    api
      .assetsFacets()
      .then((res) => {
        const fm = res.map((facet) => {
          if (facet.request_arg === "is_critical") {
            // Override the default search based on the search params in the url
            if (urlSearchQuery.get(facet.request_arg) === "false") {
              facet.selected = false;
              facet.active = true;
              setNotCriticalOnlyChecked(true);
            }

            if (urlSearchQuery.get(facet.request_arg) === "true") {
              facet.selected = true;
              facet.active = true;
              setCriticalOnlyChecked(true);
            }

            if (
              !urlSearchQuery.get(facet.request_arg) &&
              urlSearchQuery.toString()?.length > 0
            ) {
              setNotCriticalOnlyChecked(false);
              setCriticalOnlyChecked(false);
              facet.active = false;
              facet.selected = false;
            }
          } else if (facet.request_arg === "cisa_identification") {
            if (urlSearchQuery.get(facet.request_arg) === "true") {
              facet.selected = true;
              facet.active = true;
              setCisaChecked(true);
            }
            if (
              !urlSearchQuery.get(facet.request_arg) &&
              urlSearchQuery.toString()?.length > 0
            ) {
              setCisaChecked(false);
              facet.active = false;
              facet.selected = false;
            }
          } else if (facet.request_arg === "internet_facing") {
            if (urlSearchQuery.get(facet.request_arg) === "true") {
              facet.selected = true;
              facet.active = true;
              setInternetFacingChecked(true);
            }
            if (
              !urlSearchQuery.get(facet.request_arg) &&
              urlSearchQuery.toString()?.length > 0
            ) {
              setInternetFacingChecked(false);
              facet.active = false;
              facet.selected = false;
            }
          } else if (facet.request_arg === "criticality") {
            if (urlSearchQuery.has(facet.request_arg)) {
              const val = validateUrlSliderParams(
                urlSearchQuery.get(facet.request_arg),
                [0, 100]
              );
              if (val) {
                setImpactRangeValue(val);
                facet.active = true;
                facet.selected = val;
              }
            }
          } else if (facet.request_arg === "risk_score") {
            if (urlSearchQuery.has(facet.request_arg)) {
              const val = validateUrlSliderParams(
                urlSearchQuery.get(facet.request_arg),
                [0, 100]
              );
              if (val) {
                setRiskRangeValue(val);
                facet.active = true;
                facet.selected = val;
              }
            }
          } else if (facet.request_arg === "cvss_score") {
            if (urlSearchQuery.has(facet.request_arg)) {
              const val = validateUrlSliderParams(
                urlSearchQuery.get(facet.request_arg),
                [0, 10]
              );
              if (val) {
                setMaxCvssRangeValue(val);
                facet.active = true;
                facet.selected = val;
              }
            }
          } else if (
            facet.request_arg === "min_cves" ||
            facet.request_arg === "max_cves" ||
            facet.request_arg === "min_alerts"
          ) {
            if (urlSearchQuery.has(facet.request_arg)) {
              const val = parseInt(urlSearchQuery.get(facet.request_arg));
              if (!Number.isNaN(val)) {
                facet.selected = val;
                facet.active = true;
              }
            }
          } else if (facet.request_arg === "internet_facing") {
            // if (urlSearchQuery.get(facet.request_arg) === "true") {
            //   setInternetFacingChecked(true);
            // }
          } else {
            facet.active = false;
            // Set the default search based on the search params in the url
            if (urlSearchQuery.has(facet.request_arg)) {
              facet.selected = urlSearchQuery.get(facet.request_arg).split(",");
              facet.active = true;
            }
          }
          return facet;
        });

        setFacetMap(fm);

        if (urlSearchQuery.has("coverage_operator")) {
          const operator = urlSearchQuery.get("coverage_operator");
          if (["and", "or"].includes(operator)) {
            setCoverageOperator(operator);
          }
        }

        props.dispatchQueryInfo({
          sortBy: urlSearchQuery.get("sort_by") || "criticality",
          sortDirection: urlSearchQuery.get("sort_direction") || "desc",
          page: urlSearchQuery.get("page") || 1,
          rowsPerPage: urlSearchQuery.get("perpage") || props.rowsPerPage,
        });
      })
      .catch((error) => console.log(error));
  }, []);

  const clearAll = () => {
    const fm = facetMap.map((f) => {
      f.active = false;
      if (f.type === "multiselect") {
        f.selected = [];
      } else if (f.request_arg === "is_critical") {
        f.active = false;
        f.selected = false;
      } else if (f.request_arg === "cisa_identification") {
        f.active = false;
        f.selected = false;
      } else if (f.type === "text") {
        f.selected = "";
      } else if (f.type === "number") {
        f.selected = undefined;
      } else {
        f.selected = false;
      }
      return f;
    });
    setFacetMap(fm);
    props.dispatchQueryInfo({
      query: "",
      page: 1,
      sortBy: "criticality",
      sortDirection: "desc",
    });
    setClearCt(clearCt + 1);
    resetAllFilters();
  };

  function resetAllFilters() {
    // setInternetFacingChecked(false);
    setCriticalOnlyChecked(false);
    setNotCriticalOnlyChecked(false);
    setImpactRangeValue([0, 100]);
    setRiskRangeValue([0, 100]);
    setMaxCvssRangeValue([0, 10]);
    history.push({
      search: "",
    });
  }

  // Function to build query string and send it up
  const updateQuery = (coverageLogicalOperator) => {
    const activeFacets = facetMap.filter((f) => f.active);
    let query = "";

    if (coverageLogicalOperator === "and") {
      query += "&coverage_operator=and";
    }
    activeFacets.forEach((f) => {
      if (f.type === "multiselect") {
        if (f.selected.length) {
          query += `&${f.request_arg}=${f.selected
            .map((el) => el.replaceAll("%20", " ")) // need to workaround encoding spaces twice
            .map(encodeURIComponent)
            .join(",")}`;
        }
      } else {
        query += `&${f.request_arg}=${encodeURIComponent(f.selected)}`;
      }
    });
    query = query.slice(1);
    props.dispatchQueryInfo({ query });

    history.push({
      search: query,
    });
  };

  const updateFacet = (facet, values, isActive) => {
    const fm = [...facetMap].map((f) => {
      if (f.request_arg === facet) {
        f.active = isActive;
        f.selected = values;
      }
      return f;
    });

    setFacetMap(fm);
    updateQuery(coverageOperator);
    props.dispatchQueryInfo({ page: 1 });
  };

  const handleRiskRangeChange = (event, newValue) => {
    setRiskRangeValue(newValue);
  };

  const handleRiskRangeChangeCommitted = () => {
    updateFacet("risk_score", riskRangeValue.join(","), true);
  };

  const handleImpactRangeChange = (event, newValue) => {
    setImpactRangeValue(newValue);
  };

  const handleImpactRangeChangeCommitted = () => {
    updateFacet("criticality", impactRangeValue.join(","), true);
  };

  const handleTextFieldInput = ({ target: { value } }, facetName) => {
    updateFacet(facetName, value, value.length > 0);
  };

  const handleMaxCvssSlider = (event, newValue) => {
    setMaxCvssRangeValue(newValue);
  };

  const handleMaxCvssChangeCommitted = () => {
    updateFacet("cvss_score", maxCvssRangeValue.join(","), true);
  };

  const handleNumAlerts = (event) => {
    const { value } = event.target;
    if (value) {
      updateFacet("min_alerts", value, true);
    } else {
      updateFacet("min_alerts", null, false);
    }
  };

  const handleNumberCves = (event, type) => {
    const { value } = event.target;

    if (type === "lower") {
      if (value) {
        updateFacet("min_cves", value, true);
      } else {
        updateFacet("min_cves", null, false);
      }
    }

    if (type === "upper") {
      if (value) {
        updateFacet("max_cves", value, true);
      } else {
        updateFacet("max_cves", null, false);
      }
    }
  };

  const getCheckedValues = (facetName) => {
    return facetMap
      .find((f) => f.request_arg === facetName)
      ?.selected?.map(decodeURIComponent);
  };

  return (
    <div
      className={classes.container}
      style={{ width: !filterPanelOpen ? "5em" : "25em" }}
    >
      <div
        className={classes.filterPanel}
        style={{ overflowY: "auto", minHeight: "calc(100vh - 10em)" }}
        data-test="inventory-filters-panel"
      >
        {!filterPanelOpen && (
          <div style={{ textAlign: "center" }}>
            <IconButton
              aria-label="open filters"
              component="span"
              onClick={() => toggleFilters(true)}
            >
              <Badge
                badgeContent={
                  facetMap ? facetMap.filter((el) => el.active).length : 0
                }
                color="secondary"
              >
                <TuneIcon />
              </Badge>
            </IconButton>
          </div>
        )}

        {filterPanelOpen && facetMap && facetMap.length > 0 && (
          <div style={{ direction: "ltr" }}>
            <Grid
              container
              style={{ padding: ".5em" }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Badge
                  badgeContent={
                    facetMap ? facetMap.filter((el) => el.active).length : 0
                  }
                  color="secondary"
                >
                  <Button
                    disableElevation
                    variant="outlined"
                    // display="block"
                    size="small"
                    color="primary"
                    disabled={
                      facetMap &&
                      facetMap.filter((el) => el.active).length === 0
                    }
                    // className={classes.sectionTitle}
                    onClick={clearAll}
                  >
                    Clear Filters
                  </Button>
                </Badge>
              </Grid>
              <Grid item>
                <IconButton
                  aria-label="close filters"
                  onClick={() => toggleFilters(false)}
                >
                  <ChevronLeft />
                </IconButton>
              </Grid>
            </Grid>

            <Divider />

            <Grid container justifyContent="space-between">
              <Grid item>
                <div className={classes.facetContainer}>
                  <Grid container>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => {
                            setCriticalOnlyChecked(e.target.checked);
                            resolveCriticalParameter(
                              e.target.id,
                              notCriticalOnlyChecked,
                              e.target.checked
                            );
                          }}
                          id="is_critical"
                          name="High Impact"
                          size="small"
                          checked={criticalOnlyChecked}
                        />
                      }
                      label={
                        <Typography variant="body2">High Impact</Typography>
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => {
                            setNotCriticalOnlyChecked(e.target.checked);
                            resolveCriticalParameter(
                              e.target.id,
                              e.target.checked,
                              criticalOnlyChecked
                            );
                          }}
                          id="is_critical"
                          name="Low Impact"
                          size="small"
                          checked={notCriticalOnlyChecked}
                        />
                      }
                      label={
                        <Typography variant="body2">Low Impact</Typography>
                      }
                    />
                  </Grid>
                </div>
              </Grid>
            </Grid>

            <Divider />

            <Grid container justifyContent="space-between">
              <Grid item>
                <div className={classes.facetContainer}>
                  <Grid container>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => {
                            setCisaChecked(e.target.checked);
                            updateFacet(
                              e.target.id,
                              e.target.checked,
                              e.target.checked
                            );
                          }}
                          id="cisa_identification"
                          name="Known Exploited Vulnerabilities"
                          size="small"
                          checked={cisaChecked}
                        />
                      }
                      label={
                        <Typography variant="body2">
                          Known Exploited Vulnerabilities
                        </Typography>
                      }
                    />
                  </Grid>
                </div>
              </Grid>
            </Grid>

            <Divider />

            <Grid container justifyContent="space-between">
              <Grid item>
                <div className={classes.facetContainer}>
                  <Grid container>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => {
                            setInternetFacingChecked(e.target.checked);
                            updateFacet(
                              e.target.id,
                              e.target.checked,
                              e.target.checked
                            );
                          }}
                          id="internet_facing"
                          name="Internet Facing"
                          size="small"
                          checked={internetFacingChecked}
                        />
                      }
                      label={
                        <Typography variant="body2">
                          Internet Facing Assets Only
                        </Typography>
                      }
                    />
                  </Grid>
                </div>
              </Grid>
            </Grid>

            <Divider />
            <div className={classes.facetContainer}>
              <div className={classes.sliderContainer}>
                <Typography
                  id="impact-score-slider"
                  variant="body2"
                  gutterBottom
                >
                  Impact Score
                </Typography>
                <Slider
                  defaultValue={[0, 100]}
                  value={impactRangeValue}
                  valueLabelDisplay="auto"
                  aria-labelledby="impact-score-slider"
                  step={1}
                  color="primary"
                  onChange={handleImpactRangeChange}
                  onChangeCommitted={handleImpactRangeChangeCommitted}
                  min={0}
                  style={{ color: "#2E2E2E" }}
                  max={100}
                />
              </div>
            </div>
            <Divider />
            <div className={classes.facetContainer}>
              <div className={classes.sliderContainer}>
                <Typography id="risk-score-slider" variant="body2" gutterBottom>
                  Risk Score
                </Typography>
                <Slider
                  style={{ color: "#2E2E2E" }}
                  defaultValue={[0, 100]}
                  value={riskRangeValue}
                  valueLabelDisplay="auto"
                  aria-labelledby="risk-score-slider"
                  step={1}
                  onChange={handleRiskRangeChange}
                  onChangeCommitted={handleRiskRangeChangeCommitted}
                  min={0}
                  max={100}
                />
              </div>
            </div>
            <Divider />
            <div className={classes.facetContainer} style={{ padding: "1em" }}>
              <FormControl
                variant="outlined"
                size="small"
                style={{ width: "100%" }}
              >
                <InputLabel id="date-added-select">Date Added</InputLabel>
                <Select
                  labelId="date-added-select"
                  id="date-added-outlined"
                  value={
                    facetMap.find((f) => f.request_arg === "date_added")
                      ?.selected || ""
                  }
                  onChange={({ target: { value } }) =>
                    updateFacet("date_added", value, value !== "")
                  }
                  label="Date Added"
                >
                  <MenuItem value="">Anytime</MenuItem>
                  <MenuItem value={1}>Past day</MenuItem>
                  <MenuItem value={2}>Past 2 days</MenuItem>
                  <MenuItem value={7}>Past week</MenuItem>
                  <MenuItem value={14}>Past two weeks</MenuItem>
                  <MenuItem value={30}>Past 30 days</MenuItem>
                </Select>
              </FormControl>
            </div>
            <Divider />
            <div className={classes.facetContainer}>
              <div className={classes.fieldContainer}>
                <TextField
                  id="ipv4-input"
                  variant="outlined"
                  size="small"
                  placeholder="IPv4"
                  label="IPv4"
                  value={
                    facetMap.find((f) => f.request_arg === "ipv4")?.selected ||
                    ""
                  }
                  onChange={(event) => handleTextFieldInput(event, "ipv4")}
                  style={{ width: "100%" }}
                  data-test="inventory-filters-ipv4"
                />
              </div>
              <div className={classes.fieldContainer}>
                <TextField
                  id="hostname-input"
                  label="Hostname"
                  value={
                    facetMap.find((f) => f.request_arg === "hostname")
                      ?.selected || ""
                  }
                  variant="outlined"
                  size="small"
                  placeholder="Hostname"
                  onChange={(event) => handleTextFieldInput(event, "hostname")}
                  style={{ width: "100%" }}
                  data-test="inventory-filters-hostname"
                />
              </div>
              <div className={classes.fieldContainer}>
                <TextField
                  id="asset-uuid-input"
                  label="Asset UUID"
                  value={
                    facetMap.find((f) => f.request_arg === "asset_uuid")
                      ?.selected || ""
                  }
                  variant="outlined"
                  size="small"
                  placeholder="Asset UUID"
                  onChange={(event) => handleTextFieldInput(event, "asset_uuid")}
                  style={{ width: "100%" }}
                  data-test="inventory-filters-asset-uuid"
                />
              </div>
              <div className={classes.fieldContainer}>
                <TextField
                  label="CVE ID"
                  id="cve-input"
                  value={
                    facetMap.find((f) => f.request_arg === "cve")?.selected ||
                    ""
                  }
                  variant="outlined"
                  size="small"
                  placeholder="CVE ID"
                  onChange={(event) => handleTextFieldInput(event, "cve")}
                  style={{ width: "100%" }}
                  data-test="inventory-filters-cveid"
                />
              </div>
              <div className={classes.fieldContainer}>
                <TextField
                  label="Alert Name"
                  id="alert-name-input"
                  value={
                    facetMap.find((f) => f.request_arg === "alert_name")
                      ?.selected || ""
                  }
                  variant="outlined"
                  size="small"
                  placeholder="Alert Name"
                  onChange={(event) =>
                    handleTextFieldInput(event, "alert_name")
                  }
                  style={{ width: "100%" }}
                  data-test="inventory-filters-cveid"
                />
              </div>
              <div className={classes.fieldContainer}>
                <TextField
                  label="Software Package"
                  id="software-input"
                  value={
                    facetMap.find((f) => f.request_arg === "software")
                      ?.selected || ""
                  }
                  variant="outlined"
                  size="small"
                  placeholder="Software Package"
                  onChange={(event) => handleTextFieldInput(event, "software")}
                  style={{ width: "100%" }}
                  data-test="inventory-filters-software"
                />
              </div>
              <div className={classes.fieldContainer}>
                <TextField
                  label="Port"
                  id="port"
                  value={
                    facetMap.find((f) => f.request_arg === "port")?.selected ||
                    ""
                  }
                  variant="outlined"
                  size="small"
                  placeholder="Port"
                  onChange={(event) => handleTextFieldInput(event, "port")}
                  style={{ width: "100%" }}
                  data-test="inventory-filters-port"
                />
              </div>
              <div className={classes.fieldContainer}>
                <TextField
                  label="Logon Username"
                  id="uesr-logon-input"
                  value={
                    facetMap.find((f) => f.request_arg === "user_logon")
                      ?.selected || ""
                  }
                  variant="outlined"
                  size="small"
                  placeholder="Logon Username"
                  onChange={(event) =>
                    handleTextFieldInput(event, "user_logon")
                  }
                  style={{ width: "100%" }}
                  data-test="inventory-filters-logon"
                />
              </div>
            </div>

            <Divider />
            <div className={classes.facetContainer} style={{ padding: "1em" }}>
              <FormControl
                style={{ minWidth: 120, fontSize: 10 }}
                size="small"
                variant="outlined"
              >
                <InputLabel id="demo-select-small">Covered by:</InputLabel>

                <Select
                  label="Covered by:"
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={coverageOperator}
                  onChange={(e) => {
                    setCoverageOperator(e.target.value);
                    updateQuery(e.target.value);
                  }}
                >
                  <MenuItem value="or">at least 1 selected</MenuItem>
                  <MenuItem value="and">all selected</MenuItem>
                </Select>
              </FormControl>
            </div>
            {facetMap
              ?.filter(
                (el) => el.type === "multiselect" && el?.options?.length > 0
              )
              ?.sort((a, b) =>
                a.request_arg.includes("coverage") ||
                  b.request_arg.includes("coverage")
                  ? 1
                  : a.display_name > b.display_name
                    ? 1
                    : -1
              )
              ?.map((facet, idx) => {
                if (EXCLUDED_FACETS.includes(facet.request_arg)) {
                  return <></>;
                }
                return (
                  <div key={idx}>
                    <Divider />
                    <CheckboxesFacet
                      open={
                        facet.request_arg.includes("coverage") ||
                        facet?.options?.length <= 3
                      }
                      title={facet.display_name}
                      facetName={facet.request_arg}
                      onChange={updateFacet}
                      valuesAlreadyChecked={getCheckedValues(facet.request_arg)}
                      clearCt={clearCt}
                      facetOptions={
                        facetMap.find(
                          (f) => f.request_arg === facet.request_arg
                        )?.options
                      }
                    />
                  </div>
                );
              })}

            {facetMap
              ?.filter(
                (el) => el.type === "multiselect" && el?.options?.length === 0
              )
              ?.sort((a, b) => (a.display_name > b.display_name ? 1 : -1))
              ?.map((facet, idx) => {
                if (EXCLUDED_FACETS.includes(facet.request_arg)) {
                  return <></>;
                }
                return (
                  <>
                    <Divider />
                    <div key={idx} style={{ padding: "1em .5em" }}>
                      <Typography
                        variant="body2"
                        style={{ color: "gray" }}
                      >{`${facet.display_name} - No Options`}</Typography>
                    </div>
                  </>
                );
              })}
            <Divider />
            <div className={classes.fieldContainer}>
              <div className={classes.facetContainer}>
                <TextField
                  id="agent-version-input"
                  label="EDR Agent Version"
                  value={
                    facetMap.find((f) => f.request_arg === "agent_version")
                      ?.selected || ""
                  }
                  variant="outlined"
                  size="small"
                  onChange={(event) =>
                    handleTextFieldInput(event, "agent_version")
                  }
                  style={{ width: "100%" }}
                />
              </div>
            </div>
            <Divider />
            <div className={classes.facetContainer}>
              <Typography variant="body2" className={classes.heading}>
                Total Critical or High Vulnerabilties
              </Typography>
              <div className={classes.fieldContainer}>
                <div className={classes.cvesWrapper}>
                  <TextField
                    id="#-cves-lower-limit"
                    label="Min"
                    variant="outlined"
                    size="small"
                    value={
                      facetMap.find((f) => f.request_arg === "min_cves")
                        ?.selected || ""
                    }
                    onChange={(e) => handleNumberCves(e, "lower")}
                    className={classes.cvesInput}
                  />
                  to
                  <TextField
                    id="#-cves-upper-limit"
                    label="Max"
                    variant="outlined"
                    size="small"
                    value={
                      facetMap.find((f) => f.request_arg === "max_cves")
                        ?.selected || ""
                    }
                    onChange={(e) => handleNumberCves(e, "upper")}
                    className={classes.cvesInput}
                  />
                </div>
              </div>
            </div>
            <Divider />
            <div className={classes.facetContainer}>
              <div className={classes.fieldContainer}>
                <TextField
                  id="#-alerts-lower-limit"
                  label="Minimum # of Alerts"
                  variant="outlined"
                  size="small"
                  value={
                    facetMap.find((f) => f.request_arg === "min_alerts")
                      ?.selected || ""
                  }
                  onChange={(e) => handleNumAlerts(e)}
                />
              </div>
            </div>
            <Divider />
            <div className={classes.facetContainer}>
              <div className={classes.sliderContainer}>
                <Typography id="cvss-score-slider" variant="body2" gutterBottom>
                  Max CVSS Score
                </Typography>
                <Slider
                  defaultValue={[0, 10]}
                  value={maxCvssRangeValue}
                  valueLabelDisplay="auto"
                  aria-labelledby="cvss-score-slider"
                  step={1}
                  color="primary"
                  onChange={handleMaxCvssSlider}
                  onChangeCommitted={handleMaxCvssChangeCommitted}
                  min={0}
                  style={{ color: "#2E2E2E" }}
                  max={10}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Filters;
