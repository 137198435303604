import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  TableContainer,
  Paper,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import AssetIcon from "../components/AssetIcon";
import EditIcon from "@material-ui/icons/Edit";
import UserLogons from "./UserLogons";
import SeverityIcon from "../components/SeverityIcon";
import Link from "@material-ui/core/Link";
import Table from "@material-ui/core/Table";
import moment from "moment";
import { green } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useApi } from "../API";
import { palette } from "../utils/theme";
import StandardTable from "../table/GenericTable";
import { useHistory } from "react-router-dom";
import DataDiscoveryResult from "./DataDiscoveryResult";




const useStyles = makeStyles((theme) => ({
  root: theme.layout.root,
  content: theme.layout.content,
  contentWrapper: theme.layout.contentWrapper,
  loading: {
    height: "100vh",
    width: "100vw",
    position: "fixed",
    opacity: ".5",
    background: "white",
    zIndex: 10000,
    top: -50,
    left: 0,
  },
  assetBanner: {
    position: "relative",
    padding: "1em",
    // paddingRight: ".5em",
    // paddingTop: ".5rem",
    display: "flex",
    backgroundColor: "white",
    marginBottom: "2em",
  },
  secondaryNav: theme.layout.secondaryNav,
  tabPanel: {
    background: theme.palette.background,
  },
  companySection: {
    padding: theme.spacing(2),
    marginBottom: 16,
    background: "white",
    width: "100%",
  },
  wrapper: {
    position: "relative",
    margin: theme.spacing(1),
  },
  fabProgress: {
    color: green[500],
    position: "absolute",
    top: -6,
    left: -6,
    zIndex: 1,
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  grid: {
    display: "grid",
    gridGap: 5,
    gridAutoFlow: "column",
    width: "100%",
    gridTemplateColumns: "100 100",
    justifyContent: "end",
  },
}));

function ApplicationDetails() {
  const params = useParams();
  const history = useHistory();

  const { applicationId } = params;
  const [editMode, setEditMode] = useState(false);
  const [data, setData] = useState({});
  const [vulnData, setVulnData] = useState([]);
  const [userLogons, setUserLogons] = useState([]);
  const [internetFacing, setInternetFacing] = useState(false);
  const [alertData, setAlertData] = useState([]);
  const [editedApplication, setEditedApplication] = useState({});
  const api = useApi();
  const classes = useStyles();

  const [primaryFields, setPrimaryFields] = useState([{ name: 'display_name', label: 'Name' }, { name: 'total_assets', label: 'Confirmed Assets' },
  { name: 'suggested_assets', label: 'Suggested Assets' },
  { name: 'owner', label: 'Owner' }, { name: 'business_unit', label: 'Business Unit' }, { name: 'locations', label: 'Locations' }])
  const [assetData, setAssetData] = useState([])
  const cveFormat = /^CVE-\d{4}-\d{4,7}$/;
  const CVE_INFO_BASE_URL = "https://nvd.nist.gov/vuln/detail";
  const matchesCVEFormat = (cveId) => {
    return cveFormat.test(cveId);
  };

  const vulnHeaders = [
    {
      name: "cve_name",
      display_name: "Vulnerability Name",
      format: (d) => {
        return d.cve_name === "" || !d.cve_name
          ? `No name available. Vulnerability ID: ${d.cve_id}`
          : d.cve_name;
      },
    },
    {
      name: "cvss_v3",
      display_name: "CVSSv3 Score",
      format: (d) => {
        return <SeverityIcon score={d.cvss_v3} />

      }
    },
    {
      name: "cisa_identification",
      display_name: "CISA",
      format: (d) => {
        return d.cisa_identification ? "Known Exploited ⚠️" : ""
      }
    },
    {
      name: "cve_id",
      display_name: "Vulnerability ID",
      format: (d) => {
        return matchesCVEFormat(d.cve_id) ? (
          <Link
            target="_blank"
            href={`${CVE_INFO_BASE_URL}/${d.cve_id.toUpperCase()}`}
            underline="hover"
          >
            NIST {d.cve_id}
          </Link>
        ) : (
          d.cve_id
        );
      },
    },
    {
      name: "total_assets",
      display_name: "Total Assets",
    },
  ];

  const headers = [
    {
      name: "name",
      display_name: "Alias",
      format: (d) => {
        return d.name === "" || !d.name ? d.hostname : d.name;
      },
    },
    {
      name: "criticality",
      display_name: "Impact Score",
      format: (row) => {
        return (
          <AssetIcon
            criticalityPercentage={Math.floor(row.criticality).toFixed()}
            isCritical={row.is_critical}
          />
        );
      },
    },
    {
      name: "risk_score",
      display_name: "Risk Score",
      format: (row) => {
        return (
          <AssetIcon
            riskPercentage={Math.floor(row.risk_score).toFixed()}
            risk
          />
        );
      },
    },
    {
      name: "ipv4",
      display_name: "IP",
      format: (d) => {
        return d.ipv4 ? d.ipv4 : "-";
      },
    },
    {
      name: "hostname",
      display_name: "Hostname",
    },
    {
      name: "asset_type",
      display_name: "Asset Type",
    },
  ];


  useEffect(() => {

    api
      .getApplicationDetails(applicationId)
      .then((res) => {
        console.log(res)
        return setData(res[0]);

      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    api
      .getVulnsByApplication(applicationId)
      .then((res) => {
        console.log(res)
        return setVulnData(res);

      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    api.assetsFacetedSearch(`application_id=${applicationId}&sort_by=criticality&sort_direction=desc`)
      .then((res) => {
        const found = res.data.find((el) => el.internet_facing)
        console.log(found)
        setInternetFacing(!!found)
        console.log(res.data)
        return setAssetData(res.data)
      })
      .catch((err) => console.log(err))

  }, [])

  useEffect(() => {
    api.applicationAlerts(applicationId)
      .then((res) => {
        return setAlertData(res.data)
      })
      .catch((err) => console.log(err))

  }, [])

  useEffect(() => {
    api.getApplicationLogons(applicationId)
      .then((res) => {
        return setUserLogons(res.data)
      })
      .catch((err) => console.log(err))

  }, [])


  const lookupFieldValue = (fieldName) => {
    return data[fieldName.toLowerCase()];
  };

  const saveApplication = () => {
    api.updateApplication(applicationId, { ...data, ...editedApplication })
      .then(() => {
        setData({ ...data, ...editedApplication })
      }).catch((err) => console.log(err))
  }

  document.title = "KeyCaliber - Application Details";

  return (
    <Box className={classes.root}>
      <div className={classes.contentWrapper}>
        <Box className={classes.content}>

          <div
            style={{ backgroundColor: "#E9EBEE" }}
            data-item="inventory-page-asset-detail"
          >
            <Paper variant="outlined" className={classes.assetBanner}>
              {primaryFields.map((field, index) => (
                <Grid
                  container
                  key={index}
                  justifyContent="space-around"
                  direction="column"
                  alignContent="center"
                >
                  <Grid item>
                    <Typography
                      variant="h6"
                      style={{ fontSize: "medium" }}
                      data-test={`details-${field.name}`}
                    >
                      {field.name === "criticality"
                        ? Math.floor(
                          Number.parseFloat(lookupFieldValue(field.name))
                        ).toFixed(0)
                        : lookupFieldValue(field.name) || "--"}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="body2"
                      style={{ color: palette.darkGray }}
                    >
                      {field.label}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
              {!editMode ?
                <Grid
                  container
                  direction="column"
                  justifyContent="space-around"
                  alignItems="center"
                >

                  <Grid item style={{ textAlign: "center" }}>
                    <AssetIcon
                      criticalityPercentage={data.impact_score ? data.impact_score : Math.floor(
                        Number.parseFloat(lookupFieldValue("avg_impact_score"))
                      ).toFixed(0)}
                      svgWidth={45}
                    />
                  </Grid>
                  <Grid item>

                    <Typography
                      variant="body2"
                      style={{ textAlign: "center", color: palette.darkGray }}
                    >
                      Impact Score
                    </Typography>
                  </Grid>
                </Grid>
                :
                <Grid
                  container
                  direction="column"
                  justifyContent="space-around"
                  alignItems="center"
                >
                  <Grid item style={{ textAlign: "center" }}>

                    <TextField
                      label="Impact Score"
                      type="number"
                      value={editedApplication.impact_score ? editedApplication.impact_score : (data.impact_score ? data.impact_score : Math.floor(
                        Number.parseFloat(lookupFieldValue("avg_impact_score"))
                      ).toFixed(0))}
                      onChange={(e) =>
                        setEditedApplication({
                          ...editedApplication,
                          impact_score: e.target.value,
                        })
                      }
                    /></Grid></Grid>}

              <Grid container direction="column" justifyContent="flex-end">
                <Grid item style={{ textAlign: "center" }}>
                  {(
                    <AssetIcon
                      svgWidth={45}
                      risk
                      riskPercentage={Math.floor(
                        Number.parseFloat(lookupFieldValue("avg_risk_score"))
                      ).toFixed(0)}
                    />
                  )
                  }
                  <Grid item>
                    <Typography
                      variant="body2"
                      style={{ textAlign: "center", color: palette.darkGray }}
                    >
                      Risk Score
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Box style={{ marginLeft: "auto" }} justifyContent="center">
                <ButtonGroup color="secondary" variant="outlined">
                  {!editMode ? (
                    <Tooltip title="Edit Application">
                      <IconButton
                        // className={classes.assetEditButton}
                        onClick={() => { setEditMode(true) }}
                        title="Edit this Application"
                        data-test="edit-application-button"
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  ) : (<Grid direction="row">
                    <Tooltip title="Save Application">
                      <Button
                        // className={classes.assetEditButton}
                        variant="outline-primary"
                        onClick={() => { saveApplication(); setEditMode(false); }}
                        title="Save this Application"
                        data-test="save-application-button"
                      >
                        Save
                      </Button>
                    </Tooltip>
                    <Tooltip title="Cancel Application">
                      <Button
                        // className={classes.assetEditButton}
                        variant="outline-primary"
                        onClick={() => { setEditedApplication({}); setEditMode(false); }}
                        title="Cancel"
                        data-test="cancel-application-button"
                      >
                        Cancel
                      </Button>
                    </Tooltip>
                  </Grid>)}
                </ButtonGroup>
              </Box>
            </Paper>
          </div>
          <Paper className={classes.companySection} variant="outlined">
            <Typography variant="h5" component="h1">
              {!editMode && (
                <div>
                  Details
                </div>
              )}
              {editMode && <div style={{ marginBottom: 16 }}>Details</div>}
            </Typography>
            <Grid container direction="row" justifyContent="space-between" alignContent="start">

              <Grid item direction="column">
                <Grid container xs={12} spacing={2} style={{ padding: "1em" }}>
                  <Grid item container alignItems="center">
                    <Grid item xs={2}>
                      <Typography variant="subtitle2">Description</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <div>{data?.description ? data.description : '--'}</div>
                    </Grid>
                  </Grid>
                  <Grid item container alignItems="center">
                    <Grid item xs={2}>
                      <Typography variant="subtitle2">Business Process</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <div>{data?.business_process ? data.business_process : '--'}</div>
                    </Grid>
                  </Grid>
                  <Grid item container alignItems="center">
                    <Grid item xs={2}>
                      <Typography variant="subtitle2">Date Added</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      {data?.date_added ? (
                        <>
                          <span>
                            {`${moment(data?.date_added).local().fromNow()} `}
                          </span>

                          <Typography
                            style={{ color: "#797979" }}
                            display="block"
                            variant="caption"
                          >
                            {data?.date_added}
                          </Typography>
                        </>
                      ) : (
                        "--"
                      )}
                    </Grid>
                  </Grid>
                  <Grid item container alignItems="center">
                    <Grid item xs={2}>
                      <Typography variant="subtitle2">Date Last Seen</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      {data?.last_seen ? (
                        <>
                          <span>
                            {`${moment(data?.last_seen).local().fromNow()} `}
                          </span>

                          <Typography
                            style={{ color: "#797979" }}
                            display="block"
                            variant="caption"
                          >
                            {data?.last_seen}
                          </Typography>
                        </>
                      ) : (
                        "--"
                      )}
                    </Grid>
                  </Grid>
                  <Grid item container alignItems="center">
                    <Grid item xs={2}>
                      <Typography variant="subtitle2">Tags</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      {data.tags ? data.tags : '--'}
                    </Grid>
                  </Grid>
                  <Grid item container alignItems="center">
                    <Grid item xs={2}>
                      <Typography variant="subtitle2">Internet Facing</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <div>{internetFacing ? "True" : "False"}</div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item direction="column" xs={5}>
                <Grid container xs={12} spacing={2} style={{ padding: "1em" }}>
                  <Grid item container alignItems="center">
                    <Grid item xs={2}>
                      <Typography variant="subtitle2">Assets</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      {data.total_assets ? data.total_assets : '0'}
                    </Grid>
                  </Grid>
                  <Grid item container alignItems="center">
                    <Grid item xs={2}>
                      <Typography variant="subtitle2">Vulns</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      {data.total_cves ? data.total_cves : '0'}
                    </Grid>
                  </Grid>
                  <Grid item container alignItems="center">
                    <Grid item xs={2}>
                      <Typography variant="subtitle2">Alerts</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      {data.total_alerts ? data.total_alerts : '0'}
                    </Grid>
                  </Grid>
                  <Grid item container alignItems="center">
                    <Grid item xs={2}>
                      <Typography variant="subtitle2">RTO</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      {!editMode ?
                        <div>{data.rto ? data.rto : '--'} </div> :
                        <Grid item alignContent="center"><TextField
                          value={data.rto}
                          type="number"
                          onChange={(e) =>
                            setEditedApplication({
                              ...editedApplication,
                              rto: e.target.value,
                            })
                          }

                        /></Grid>
                      }
                    </Grid>
                  </Grid>
                  <Grid item container alignItems="center">
                    <Grid item xs={2}>
                      <Typography variant="subtitle2">RPO</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      {!editMode ?
                        <div>{data.rpo ? data.rpo : '--'} </div> :
                        <Grid item alignContent="center"><TextField
                          value={data.rpo}
                          type="number"
                          onChange={(e) =>
                            setEditedApplication({
                              ...editedApplication,
                              rpo: e.target.value,
                            })
                          }

                        /></Grid>
                      }
                    </Grid>
                  </Grid>
                  <Grid item container alignItems="center">
                    <Grid item xs={2}>
                      <Typography variant="subtitle2">Added By</Typography>
                    </Grid>
                    <Grid item xs={10}>
                      {data.added_by ? data.added_by : '--'}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>

          {/* <Accordion elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ backgroundColor: "#F5F7F9" }}
            >
              <Typography
                className={classes.heading}
                style={{ color: palette.purple1 }}
              >
                Recommendations
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F5F7F9" }}>
              <Paper className={classes.companySection} variant="outlined">
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  className={classes.row}
                >
                  <Box item component={Grid} xs={12}>
                    <Typography variant="overline" display="block">
                      No recommendations as of right now. Please check again later.
                    </Typography>
                  </Box>
                </Grid>
              </Paper>
            </AccordionDetails>
          </Accordion> */}
          <Accordion elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ backgroundColor: "#F5F7F9" }}
            >
              <Typography
                className={classes.heading}
                style={{ color: palette.purple1 }}
              >
                Assets
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F5F7F9" }}>
              <Paper className={classes.companySection} variant="outlined">
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  className={classes.row}
                >
                  <Box item component={Grid} xs={12}>
                    <StandardTable
                      firstColWidth={300}
                      onClick={(row) => {
                        history.push(
                          `/app/asset/` + row.id
                        );
                      }}
                      data={{
                        headers,
                        data: assetData
                      }}
                    />
                  </Box>
                </Grid>
              </Paper>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ backgroundColor: "#F5F7F9" }}
            >
              <Typography
                className={classes.heading}
                style={{ color: palette.purple1 }}
              >
                Vulnerabilities
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F5F7F9" }}>
              <Paper className={classes.companySection} variant="outlined">
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  className={classes.row}
                >
                  <Box item component={Grid} xs={12}>
                    <StandardTable
                      firstColWidth={300}
                      onClick={(row) => {
                        history.push(
                          `/app/inventory/assets?cve=${row.cve_id}${criticalOnly ? "&is_critical=true" : ""
                          }${internetFacing ? "&internet_facing=true" : ""}`
                        );
                      }}
                      data={{
                        headers: vulnHeaders,
                        data: vulnData,
                      }}
                    />
                  </Box>
                </Grid>
              </Paper>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ backgroundColor: "#F5F7F9" }}
            >
              <Typography
                className={classes.heading}
                style={{ color: palette.purple1 }}
              >
                Alerts
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F5F7F9" }}>
              <Paper className={classes.companySection} variant="outlined">
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  className={classes.row}
                >
                  <Box item component={Grid} xs={12}>
                    <TableContainer>
                      <Table size="small" aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Alert</TableCell>
                            <TableCell>Severity</TableCell>
                            <TableCell>Assets</TableCell>
                            <TableCell>with Confidential Data</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {alertData?.map((row, idx) => (
                            <TableRow
                              key={idx}
                              component="a"
                              target="_blank"
                              hover
                              href={`/app/inventory/assets?alert_name=${row.alert_name}`}
                            >
                              <TableCell scope="row">{row.alert_name}</TableCell>
                              <TableCell>
                                <SeverityIcon
                                  score={parseFloat(row.vendor_score || 0).toFixed(1)}
                                />
                              </TableCell>
                              <TableCell>{row.total_assets}</TableCell>
                              <TableCell>{row.total_confidential || "--"}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Grid>
              </Paper>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ backgroundColor: "#F5F7F9" }}
            >
              <Typography
                className={classes.heading}
                style={{ color: palette.purple1 }}
              >
                Logons
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F5F7F9" }}>
              <Paper className={classes.companySection} variant="outlined">
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  className={classes.row}
                >
                  <Box item component={Grid} xs={12}>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <UserLogons data={userLogons} />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Paper>
            </AccordionDetails>
          </Accordion>
          <Accordion elevation={0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              style={{ backgroundColor: "#F5F7F9" }}
            >
              <Typography
                className={classes.heading}
                style={{ color: palette.purple1 }}
              >
                Data
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: "#F5F7F9" }}>
              <Paper className={classes.companySection} variant="outlined">
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  className={classes.row}
                >
                  <Box item component={Grid} xs={12}>
                    <Grid container spacing={4}>
                      <Grid item xs={12}>
                        <DataDiscoveryResult applicationId={applicationId} />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Paper>
            </AccordionDetails>
          </Accordion>
        </Box>
      </div>
    </Box>
  );
}

export default ApplicationDetails;
