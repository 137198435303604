import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  MenuItem,
  Menu,
  Paper,
  Grid,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import { useHistory } from "react-router-dom";
import { useApi } from "../API";
import { numberWithCommas } from "../utils";
import NewModal from "./ApplicationNewModal";
import EditModal from "./ApplicationEditModal";
import DeleteModal from "./ApplicationDeleteModal";
import ResilienceIcon from "../icons/resilienceIcon";

const useStyles = makeStyles(() => ({
  textField: {
    minWidth: "70%",
  },
  tableText: {
    whiteSpace: "nowrap",
    minWidth: "120px",
    maxWidth: "120px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

function BusinessProcessesTable() {
  const api = useApi();
  const history = useHistory();
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [newModalOpen, setNewModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [visible, setVisible] = useState();

  const [refreshData, setRefreshData] = useState(false);
  const [modalApplication, setModalApplication] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const toggleRefresh = () => {
    setRefreshData(!refreshData);
  };

  const handleClickNewBusinessProcess = () => {
    setNewModalOpen(true);
  };

  useEffect(async () => {
    setLoading(true);
    try {
      const result = await api.getBusinessProcesses();
      setData(result);
    } catch (e) {
      setError(true);
      setData([]);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [refreshData]);

  const handleClickMenu = (event, businessProcess) => {
    setModalApplication(businessProcess);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setModalApplication(null);
    setAnchorEl(null);
  };

  const handleHover = (rowId) => {
    setVisible(rowId);
  };

  const handleMouseLeave = () => {
    setVisible(null);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>There was an error fetching the data.</div>;
  }

  return (
    <>
      <TableContainer>
        <Table size="small" component={Paper} variant="outlined">
          <colgroup>
            <col />
            <col style={{ width: "20%" }} />
            <col />
            <col />
            <col />
            <col />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Business Applications</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((businessProcess, i) => (
              <>
                <TableRow
                  onMouseLeave={handleMouseLeave}
                  onMouseOver={() => handleHover(businessProcess.id)}
                  hover
                  key={i}
                >
                  <TableCell
                    className={classes.tableText}
                  >
                    {businessProcess.display_name}
                  </TableCell>
                  <TableCell
                    className={classes.tableText}
                  >
                    {businessProcess.total_applications}
                  </TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default BusinessProcessesTable;
